<template>
  <div>
    <guest-navbar />
    <guest-slider>
      <template slot="content">
        <div class="mb-4">
          <h5>Recover your account</h5>
          <hr />
          <form v-if="!sent" @submit.prevent="submit">
            <div class="alert alert-danger" v-if="invalid_email">
              Invalid Email
            </div>
            <div class="form-group">
              <label>Email</label>
              <input
                type="email"
                placeholder="Email"
                class="form-control"
                v-model="account.email"
              />
            </div>
            <div class="form-group text-right">
              <button class="btn btn-secondary btn-sm">
                Send Recovery Email
              </button>
            </div>
            <p class="mb-2">
              <router-link :to="{ name: 'login' }">Back</router-link>
            </p>
          </form>
          <div v-if="sent">
            <div class="alert alert-success">
              Click on the link we have sent to your email to recover your
              account.
            </div>
          </div>
        </div>
      </template>
    </guest-slider>
    <guest-footer />
    <guest-quote />
  </div>
</template>

<script>
export default {
  data() {
    return {
      sent: false,
      account: { email: "" },
      invalid_email: false,
    };
  },

  methods: {
    submit() {
      this.invalid_email = false;
      this.$loader.start();

      this.$axios
        .post("/api/v1/reset-password", this.account)
        .then((response) => {
          let status = response.data;
          this.$loader.stop();

          if (status == 0) {
            this.invalid_email = true;
          } else {
            this.sent = true;
          }
        });
    },
  },
};
</script>
